import React, { Component } from 'react'
import { faExclamationCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cartIcon from '../../assets/img/card-icon.svg';
import shynrIcon from '../../assets/img/shynr.svg';
import Logo from '../../assets/img/shynr.png';

export default class errorMessage extends Component {
    render() {
        return (
            <div>
                <div className="w-100 my-5">
                    <div className="flex justify-center my-5 pt-7">
                        <img alt="badge" src={Logo} className="w-56" />
                    </div>
                    <div className="max-w-3xl mx-auto shadow-md rounded-xl bg-white overflow-hidden">
                        <div className="relative">
                            <div className="relative">
                                <div className="overflow-hidden h-3 mb-4 text-xs flex rounded bg-indigo-200">
                                    <div style={{ width: "100%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="px-5 text-center">
                            <div className="mt-3 mb-3">
                                <FontAwesomeIcon icon={faExclamationCircle} className="text-6xl font-normal" style={{ color: '#ff7575', fontSize: '60px' }} />
                            </div>
                            <label className="text-black-600 tracking-wider font-bold text-4xl"> Please try again.</label>
                        </div>
                        <div className="px-5 text-center mt-3">
                            <label className="text-gray-400 font-normal text-2xl">The link is not valid or expired.</label>
                        </div>
                        <br />
                        <br />
                    </div>
                    <br />
                    <div className="flex items-center justify-center pb-5">
                        <span className="mr-2 font-medium">
                            Powered by Shynr
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
