import { faStar, faStarHalfAlt, faTimes, faStarAndCrescent, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import React, { Component, Suspense } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Thumbnail, Caption, DropZone } from '@shopify/polaris';
import * as Constants from '../../utils/graphql_queries.js';
import { client, widgetSettingsShopURL } from '../../utils/config';
import ReactStars from "react-rating-stars-component";
import CommonConfig from '../../utils/constant.js';
import ErrorNotFound from '../404Error/404Error';
import Loading from '../Loading/Loading';
import WebFont from 'webfontloader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StarRatingComponent from 'react-star-rating-component';
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialState = {
    files: '',
    validImageTypes: ['image/jpeg', 'image/png'],
    IsValid: false,
    ProductID: '',
    ShopID: '',
    name: '',
    nameErr: false,
    nameHelperText: '',
    email: '',
    emailErr: false,
    emailHelperText: "",
    reviewtitle: '',
    reviewtitleErr: false,
    reviewtitleHelperText: "",
    reviewbody: '',
    reviewbodyErr: false,
    reviewbodyHelpertext: '',
    ratingvalue: parseInt(''),
    ratingErr: false,
    ratingHelperText: "",
    ratingColor: '#FF8800',
    fileErrmsg: '',
    mediaCount: 0,
    videoUpload: false,
    allowedVideo: 1,
    videoCount: 1,
    imgCount: 1,
    val: "",
    valCount: 0,
    disable: false,
    loading: true,
    dropzoneDisplay: 'block',
    allow_media_upload: null,
    allow_video_upload: null,
    loading  : false
}

export default class WriteReview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState

    }

    componentDidMount = () => {
        var url = new URL(window.location.href);
        var productid = url.searchParams.get("id");
        var widgetSettingsShopURL = ''
        if (url.searchParams.get("shop_url")) {
            widgetSettingsShopURL = url.searchParams.get("shop_url");
        }
        if (!CommonConfig.isEmpty(productid) && !CommonConfig.isEmpty(widgetSettingsShopURL)) {
            this.setState({ IsValid: true, ProductID: productid })
            this.getWidgetsAdminSettings(widgetSettingsShopURL);
        } else {
            this.setState({ loading: false })
        }
    }

    handleChange = (e, type) => {
        if (type === "reviewer_name") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({
                    name: e.target.value,
                    nameErr: true,
                    nameHelperText: "Please enter your name",
                })
            }
            else {
                this.setState({
                    name: e.target.value,
                    nameErr: false,
                    nameHelperText: "",
                })
            }
        }
        if (type === "reviewer_email") {


            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({
                    email: e.target.value,
                    emailErr: true,
                    emailHelperText: "Please enter your email",
                })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.email)) {
                this.setState({
                    email: e.target.value,
                    emailErr: true,
                    emailHelperText: "Please enter valid email",
                })

            }
            else {
                this.setState({
                    email: e.target.value,
                    emailErr: false,
                    emailHelperText: "",
                })
            }
        }
        if (type === "review_title") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({
                    reviewtitle: e.target.value,
                    reviewtitleErr: true,
                    reviewtitleHelperText: "Please enter review title",
                })
            }

            else {
                this.setState({
                    reviewtitle: e.target.value,
                    reviewtitleErr: false,
                    reviewtitleHelperText: "",
                })
            }
        }
        if (type === "review_body") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({
                    reviewbody: e,
                    reviewbodyErr: true,
                    reviewbodyHelpertext: "Please enter review desription",
                })
            }
            else {
                this.setState({
                    reviewbody: e,
                    reviewbodyErr: false,
                    reviewbodyHelpertext: "",
                })
            }
        }

    }

    checkValid = (e) => {
        const { name, email, reviewtitle, reviewbody } = this.state;
        let isValid = true
        if (CommonConfig.isEmpty(name)) {
            isValid = false
            this.setState({
                nameErr: true,
                nameHelperText: "Please enter your name"
            })
        }
        if (CommonConfig.isEmpty(reviewtitle)) {
            isValid = false
            this.setState({
                reviewtitleErr: true,
                reviewtitleHelperText: "Please enter the title"
            })
        }
        if (CommonConfig.isEmpty(reviewbody)) {
            isValid = false
            this.setState({
                reviewbodyErr: true,
                reviewbodyHelpertext: "Please enter description"
            })
        }
        if (CommonConfig.isEmpty(email)) {
            isValid = false
            this.setState({
                emailErr: true,
                emailHelperText: "Please enter your email"
            })
        }
        else if (!email.match(CommonConfig.RegExp.email)) {
            isValid = false
            this.setState({
                emailErr: true,
                emailHelperText: "Please enter valid email"
            })
        }
        return isValid
    }

    getWidgetsAdminSettings = (widgetSettingsShopURL) => {
        fetch(widgetSettingsShopURL).then(res => { return res.json() }).then(result => {
            if (result.length > 0) {

                this.setState({
                    ShopID: result[0].shop_id,
                    mediaCount: Number(result[0].allow_media_upload),
                    videoUpload: result[0].allow_video_upload === "yes" ? true : false,
                    ratingColor: result[0].rating_star_color,
                    widget_font: result[0].widget_font,
                    widget_font_name: result[0].widget_font_name,
                })

                WebFont.load({
                    google: {
                        families: [result[0].widget_font]
                    }
                })
                // setTimeout(() => { this.setState({ loading: false }) }, 2000);
            }
        })
    }

    WriteReview = async (res) => {
        var data = {
            input: {
                shop_id: this.state.ShopID,
                product_id: this.state.ProductID.toString(),
                product_title: CommonConfig.isEmpty(this.state.product_title) ? '' : this.state.product_title,
                rating: this.state.rating.toString(),
                review_title: this.state.reviewtitle,
                review_body: this.state.reviewbody,
                reviewer_name: this.state.name,
                reviewer_email: this.state.email,
                media1: CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media1,
                media2: CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media2,
                media3: CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media3,
                media4: CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media4,
            }
        }

        await client
            .mutate({
                mutation: Constants.WRITER_REVIEW,
                variables: data,
            })
            .then(async result => {
                if (result.data.ProductReview.data_status === "success") {
                    this.props.history.push('/storefront/successmessagewritereview');
                    this.setState({loading : false})
                }
                else{
                    this.setState({loading : false})
                }
            }).catch((error) => {
                this.setState({loading : false})
            });
    }

    uploadImage = async () => {
        if (this.checkValid()) {
            this.setState({loading : true})
            let files = this.state.files.length ? this.state.files.map(files => { return files.file }) : []
            if (files.length) {
                var data = {
                    file: files
                }
                var formData = new FormData()
                formData.append('operations', JSON.stringify({ "query": "mutation UploadReviewImage($file: [Upload!]!) {\nUploadReviewImage(file: $file){\ndata_status\ndata_message\nmedia1\nmedia2\nmedia3\nmedia4\n}\n}", "variables": { "file": null }, "operationName": "UploadReviewImage" }))

                if (data.file.length == 1) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"] }))
                    formData.append(0, data.file[0])
                }
                else if (data.file.length == 2) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], }))
                    formData.append(0, data.file[0])
                    formData.append(1, data.file[1])
                }
                else if (data.file.length == 3) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.3"], }))
                    formData.append(0, data.file[0])
                    formData.append(1, data.file[1])
                    formData.append(2, data.file[2])
                }
                else {

                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.2"], "3": ["variables.file.3"] }))
                    formData.append(0, data.file.length ? data.file[0] : null)
                    formData.append(1, data.file.length > 1 ? data.file[1] : null)
                    formData.append(2, data.file.length > 2 ? data.file[2] : null)
                    formData.append(3, data.file.length > 3 ? data.file[3] : null)
                }
                fetch(client.link.options.uri, {
                    method: 'POST',
                    header: {
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json; charset=UTF-8",
                    },
                    body: formData
                }).then(res => { return res.json() }).then(async res => {
                    await this.WriteReview(res)
                })
            }
            else {
                await this.WriteReview({})
            }
        }
    }
    // handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
    //     let filetype = []
    //     if (this.state.videoUpload) {
    //         filetype = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4']
    //     }
    //     else {
    //         filetype = ['image/png', 'image/jpeg', 'image/jpg']
    //     }

    //     if (this.state.files.length < this.state.mediaCount + 1 && acceptedFiles.length < this.state.mediaCount + 1) {

    //         _dropFiles.map(files => {
    //             if (filetype.includes(files.type)) {

    //                 if (files.type.includes('image') && this.state.imgCount < this.state.mediaCount + 1) {
    //                     if (files.size < 3000000) {
    //                         this.state.imgCount += 1
    //                         this.setState({ files: [...this.state.files, ...acceptedFiles], media1: acceptedFiles[0], fileErrmsg: "", dropzoneDisplay: 'none' })
    //                     }
    //                     else this.setState({ fileErrmsg: ' image size should be less than 3 MB' })
    //                 }

    //                 if (files.type.includes('video')) {
    //                     if (acceptedFiles.length < this.state.allowedVideo + 1 && this.state.videoCount < 2) {
    //                         if (files.size < 30000000) {
    //                             this.state.videoCount += 1
    //                             this.setState({ files: [...this.state.files, ...acceptedFiles], media1: acceptedFiles[0], fileErrmsg: "", dropzoneDisplay: 'none' })
    //                         }
    //                         else this.setState({ fileErrmsg: ' video size should be less than 30 MB' })
    //                     }
    //                     else this.setState({ fileErrmsg: `Only ${this.state.allowedVideo} video is allowed` })
    //                 }
    //             }
    //             else this.setState({
    //                 fileErrmsg: files.type == 'video/mp4' ? 'Video not allow' : `${files.type} file not supported`
    //             })
    //         })

    //     }
    //     else this.setState({
    //         fileErrmsg: this.state.videoUpload ? `you can upload upto ${this.state.mediaCount} Images and  ${this.state.allowedVideo} video only` : `you can upload upto ${this.state.mediaCount} Images`
    //     })
    // };
    handleDropZoneDrop = (fileItems) => {
        // console.log("fileItems", fileItems)
        let filetype = []
        if (this.state.videoUpload) {
            filetype = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4']
        }
        else {
            filetype = ['image/png', 'image/jpeg', 'image/jpg']
        }

        if (this.state.files.length < this.state.mediaCount + 1 && fileItems.length < this.state.mediaCount + 1) {

            for (let i = 0; i < fileItems.length; i++) {
                let files = fileItems[i]
                if (filetype.filter(x=>x == files.fileType).length) {
                    // debugger
                    if (files.fileType.includes('image') && this.state.imgCount < this.state.mediaCount + 1) {
                        if (files.fileSize < 3000000) {
                            this.setState({ 
                                // files: [...this.state.files, files.file], 
                                imgCount: this.state.imgCount + 1, fileErrmsg: "", dropzoneDisplay: 'none' })
                            
                        }
                        else {
                            this.setState({ fileErrmsg: ' image size should be less than 3 MB' })
                            break;
                        }
                    }

                    if (files.fileType.includes('video')) {
                        if (
                            // files.file.length < this.state.allowedVideo + 1 && this.state.videoCount < 2
                            fileItems.filter(x=>x.fileType == 'video/mp4').length < 2
                            ) {
                            if (files.fileSize < 30000000) {
                                this.setState({ 
                                    // files: [...this.state.files, files.file], 
                                    videoCount: this.state.videoCount + 1, fileErrmsg: "", dropzoneDisplay: 'none' })
                            }
                            else {
                                this.setState({ fileErrmsg: ' Video size should be less than 30 MB' })
                                break;
                            }
                        }
                        else {
                            this.setState({ fileErrmsg: `Only ${this.state.allowedVideo} video is allowed` })
                            break;
                        }
                    }
                }
                else {
                    this.setState({
                        fileErrmsg: files.fileType == 'video/mp4' ? 'Video not allowed' : `${files.fileType.charAt(0).toUpperCase() + files.fileType.slice(1,files.fileType.length)} file not supported`
                    })
                    break;
                }
            }

            this.setState({ files: this.state.fileErrmsg ? []: fileItems })

        }
        else {
            this.setState({
                fileErrmsg: this.state.videoUpload ? `You can upload upto ${this.state.mediaCount} images and  ${this.state.allowedVideo} video only` : `You can upload upto ${this.state.mediaCount} images`
            })
        }
    };

    render() {
        const { loading, files, validImageTypes, IsValid, widget_font_name } = this.state;
        return (
            <Suspense fallback={<div>Loading...</div>}>
                {/* // loading ?
            //     <Loading layoutType="writeReview"/>
            //     : */}
            
                {loading ? 
                    <div className='h-100 d-flex align-items-center justify-center'>
                        <div>   
                            <div className='loadingspinner m-auto mb-4'></div>
                            <span className='text-gray-400 font-normal text-3xl'>Please wait....</span>
                        </div>
                    </div>
                :
                CommonConfig.isEmpty(this.state.ProductID) ?
                    <ErrorNotFound />
                    :
                    <div className="bg-white relative z-10" style={{ fontFamily: widget_font_name }}>
                        <div className="sticky z-10 top-0 bg-white">
                            <div className="flex items-center justify-between p-4">
                                <h1 className="font-medium text-3xl text-black-500">Write a review</h1>
                                {/* <button><FontAwesomeIcon icon={faTimes} className="text-3xl font-normal" /></button> */}
                                <button onClick={() => window.parent.postMessage('writereviewclose', '*')}><FontAwesomeIcon icon={faTimes} className="text-3xl font-normal" /></button>

                            </div>
                            <hr className="mb-4" />
                        </div>
                        <div style={{ height: 'calc(100vh - 151px)', overflow: 'auto' }}>
                            <div className="grid grid-cols-2 gap-4 px-6">
                                <div className="">
                                    <label className="text-gray-500">Full Name</label> <span style={{ color: 'red' }} class="required">*</span>
                                    <input type="text" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" value={this.state.reviewer_name} onChange={(e) => this.handleChange(e, "reviewer_name")} name="pseudo" maxLength="30" placeholder="e.g. Sara Smith" />
                                    <span style={{ color: 'red' }}> {this.state.nameHelperText}</span>
                                </div>
                                <div className="">
                                    <label className="text-gray-500">Email address</label><span style={{ color: 'red' }} class="required">*</span>
                                    <input type="text" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.reviewer_email} onChange={(e) => this.handleChange(e, "reviewer_email")} placeholder="e.g. sara@your-email.com" />
                                    <span style={{ color: 'red' }}>     {this.state.emailHelperText}</span>
                                </div>

                            </div>
                            <br />
                            <div className="px-6">
                                <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Rate this product</label><span style={{ color: 'red' }} class="required">*</span>
                                <div className="mt-3">
                                    <StarRatingComponent
                                        name={`rate-`}
                                        emptyStarColor={"gray"}
                                        starColor={this.state.ratingColor}
                                        renderStarIcon={() => <FontAwesomeIcon icon={faStar} className="text-3xl font-normal mr-2"
                                        />}
                                        starCount={5}
                                        value={this.state.rating}
                                        onStarHover={(e) => {
                                            this.setState({ rating: e })
                                        }}
                                        onStarHoverOut={(e) => {
                                            this.setState({ rating: e })
                                        }}
                                        onStarClick={(e) => {
                                            this.setState({ rating: e })
                                        }}
                                    />
                                    {/* <ReactStars
                                        count={5}
                                        onChange={(e) => this.setState({ rating: e })}
                                        size={30}
                                        isHalf={false}
                                        emptyIcon={<FontAwesomeIcon icon={faStarAndCrescent} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                        halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} className="text-3xl ml-2 font-normal" style={{ color: this.state.ratingColor }} />}
                                        fullIcon={<FontAwesomeIcon icon={faStar} className="text-3xl font-normal" style={{ color: this.state.ratingColor }} />}
                                        activeColor={this.state.ratingColor}
                                    /> */}
                                </div>
                            </div>
                            <br />
                            <div className="px-6 mb-6">
                                <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Review this product</label>
                                <div className="mt-3">
                                    <label className="text-gray-500">Review title</label><span style={{ color: 'red' }} class="required">*</span>
                                    <input type="text" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.review_title} onChange={(e) => this.handleChange(e, "review_title")} maxLength="60" />
                                    <span style={{ color: 'red' }}>  {this.state.reviewtitleHelperText}</span>
                                </div>
                                <br />
                                <div>
                                    <label className="text-gray-500">Review description</label><span style={{ color: 'red' }} class="required">*</span>
                                    {/* <textarea rows="4" type="text" className="resize-none rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.review_body} onChange={(e) => this.handleChange(e, "review_body")} maxLength="2000" /> */}
                                    <div className='add-border-radius-quill'>
                                        <ReactQuill
                                            style={{ height: "100px" }}
                                            theme={'snow'}
                                            value={this.state.reviewbody}
                                            onChange={(e) => this.handleChange(e, "review_body")}
                                            // placeholder={"Write review"}
                                            modules={{ toolbar: null }}
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>  {this.state.reviewbodyHelpertext}</span>
                                </div>
                            </div>
                            {this.state.fileErrmsg ? 
                            <div className='w-100 px-6'>
                                <label className='font-weight-500 text-center' style={{ color: 'red' }}>  {this.state.fileErrmsg}</label>
                            </div>
                            : 
                            null}
                            {this.state.mediaCount > 0 ?
                                <div className="dropzone-design-widget px-6">
                                    {/* <DropZone style={{ width: 363 }} onDrop={this.handleDropZoneDrop} allowMultiple={true}>
                                        {files ? files.map((file, index) => (<Stack alignment="center" key={index}>
                                            <Thumbnail
                                                size="small"
                                                alt={file.name}
                                                source={
                                                    validImageTypes.includes(file.type)
                                                        ? window.URL.createObjectURL(file)
                                                        : file
                                                }
                                            />
                                            <div>
                                                {file.name} <Caption>{file.size} bytes</Caption>
                                            </div>

                                        </Stack>)) : <DropZone.FileUpload style={{ display: this.state.dropzoneDisplay + '!important' }} actionTitle="Upload Media" actionHint={this.state.videoUpload ? "JPG, JPEG or PNG (max size 3 MB),  MP4 (max size 30 MB)" : "JPG, JPEG or PNG (max size 3 MB)"} />}
                                        <span style={{ color: 'red' }}>  {this.state.fileErrmsg}</span>
                                    </DropZone> */}
                                    <FilePond
                                        ref={ref => (this.pond = ref)}
                                        files={this.state.files}
                                        allowMultiple={true}
                                        allowReorder={true}
                                        credits={''}
                                        instantUpload={true}
                                        maxFiles={this.state.mediaCount}
                                        // server="./"
                                        name="files"
                                        labelIdle={this.state.videoUpload ? 'JPG, JPEG or PNG (max size 3 MB),  MP4 (max size 30 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>' : 'JPG, JPEG or PNG (max size 3 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>'}
                                        // oninit={() => this.handleDropZoneDrop()}
                                        // onupdatefiles={fileItems => {
                                        //     this.setState({
                                        //         files: fileItems.map(fileItem => fileItem.file)
                                        //     });
                                        // }}
                                        // onaddfile={(error,fileItems) => {
                                        //     this.handleDropZoneDrop(fileItems)
                                        // }}
                                        onupdatefiles={(fileItems) => {
                                            this.handleDropZoneDrop(fileItems)
                                        }}
                                    />
                                </div>
                                : null
                            }
                        </div>
                        <div className="sticky z-10 bottom-0 bg-white">
                            <hr className="mt-4" />
                            <div className="flex justify-end p-4">
                                <button type="button" className="py-3 px-24 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                    onClick={async (e) => await this.uploadImage()} disabled={this.state.disable} >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </Suspense>

        )
    }
}

