import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import verifyBadgeWhite from "../../assets/img/verify-badge-white.svg";
import verifyBadge from "../../assets/img/verify-badge.svg";
import verifyBadgeGreen from "../../assets/img/verify-badge.svg";
import { faShareAlt, faStar, faThumbsUp, faThumbsDown, faStarHalfAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarO, faStarHalf } from '@fortawesome/free-regular-svg-icons';
import * as Constants from '../../utils/graphql_queries.js';
import { client } from '../../utils/config';
import moment from "moment";
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import CommonConfig from '../../utils//constant';
import Loading from '../Loading/Loading';
import ErrorNotFound from '../404Error/404Error';
import WebFont from 'webfontloader';
import shynrIcon from '../../assets/img/shynr.svg';
import ImgNotFound from "../../assets/img/company-Image.png";
import ReactHtmlParser from 'react-html-parser'
import FloatingTab from '../FloatingTab/FloatingTab';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const initialState = {
    handle_product: "",

    product: [],
    openTab: 1,
    tabColor: "black",
    shopReview: [],
    widget_design: "list",
    loadMoreCount: 0,
    loadMoreShopCount: 0,
    loading: false,
    LoadMoreTotal: 1,
    loadMorepage: 1,
    LoadMoreShopTotal: 1,
    loadMoreShoppage: 1,
    sortByProduct: '',
    sortByShop: '',
    sortByProductValue: '',
    sortByShopValue: '',
    pageloading: true,
    isNotFound: false,
    timeZone: '',
    hide_product_link : ''
}

export default class CustomerReviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        // this.getProductReviews(6, "oldest", 25, 1);
        // this.getListShopReviews(1, "oldest", 25);
        var url = new URL(window.location.href);
        var widgetSettingsShopURL = ''
        if (url.searchParams.get("shop_url")) {
            widgetSettingsShopURL = url.searchParams.get("shop_url");
        }
        if (!CommonConfig.isEmpty(widgetSettingsShopURL)) {
            this.getWedigetsAdminSettings(widgetSettingsShopURL);
        } else {
            this.setState({ pageloading: false })
        }
        let root = document.getElementById('root');
        let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
        window.top.postMessage(message, "*");
    }
    checkLikeUnlike(key, id) {
        var LikeUnlikes = JSON.parse(localStorage.getItem(key));

        if (LikeUnlikes != null && LikeUnlikes.includes(id)) {
            return false
        }

        if (LikeUnlikes != null) {
            LikeUnlikes.push(id);
        }
        else {
            LikeUnlikes = [id];
        }
        localStorage.setItem(key, JSON.stringify(LikeUnlikes));
        return true
    }
    upvoteProduct = async (item, idx) => {
        // debugger
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("upvoteProduct", item.id)) {
            var tempProductReviewList = this.state.product;
            tempProductReviewList[idx]['upvote_count'] = parseInt(tempProductReviewList[idx]['upvote_count'], 10) + 1;
            this.setState({ product: tempProductReviewList })
            await client
                .mutate({
                    mutation: Constants.PRODUCT_UPVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewUpvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }

    downvoteProduct = async (item, idx) => {
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("downvoteProduct", item.id)) {
            var tempProductReviewList = this.state.product;
            tempProductReviewList[idx]['downvote_count'] = parseInt(tempProductReviewList[idx]['downvote_count'], 10) + 1;
            this.setState({ product: tempProductReviewList })
            await client
                .mutate({
                    mutation: Constants.PRODUCT_DOWNVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewDownvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }
    upvoteShop = async (item, idx) => {
        // debugger
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("upvoteShop", item.id)) {
            var tempShopReviewList = this.state.shopReview;
            tempShopReviewList[idx]['upvote_count'] = parseInt(tempShopReviewList[idx]['upvote_count'], 10) + 1;
            this.setState({ shopReview: tempShopReviewList })
            await client
                .mutate({
                    mutation: Constants.SHOP_UPVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewUpvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }
    downvoteShop = async (item, idx) => {
        var data = {
            "review_id": item.id
        }
        if (this.checkLikeUnlike("downvoteShop", item.id)) {
            var tempShopReviewList = this.state.shopReview;
            tempShopReviewList[idx]['downvote_count'] = parseInt(tempShopReviewList[idx]['downvote_count'], 10) + 1;
            this.setState({ shopReview: tempShopReviewList })
            await client
                .mutate({
                    mutation: Constants.SHOP_DOWNVOTE,
                    variables: data

                })
                .then(async result => {
                    // console.log("result", result.data.ProductReviewDownvote.data_message)
                }).catch((error) => {
                    // console.log("result", error)
                });
        }
    }
    shareshopreview = async () => {
        window.mainurl = window.top.url;
        // console.log("test", window.shoporigin);
        return false;
    }

    getWedigetsAdminSettings = (widgetSettingsShopURL) => {
        fetch(widgetSettingsShopURL).then(res => {
            if (res.status == 404) {
                return ({ status: 404 })
            } else { return res.json() }
        }).then(result => {
            if (!CommonConfig.isObjectEmpty(result) && result.status == 404) {
                this.setState({ pageloading: false, isNotFound: true })
            } else {
                this.setState({
                    background_color: result[0].background_color,
                    card_widget: result[0].card_widget,
                    customer_photo: result[0].customer_photo,
                    widget_design: result[0].product_widget_type,
                    display_customer_image_box: result[0].display_customer_image_box,
                    floating_tab_background_color: result[0].floating_tab_background_color,
                    floating_tab_font_color: result[0].floating_tab_font_color,
                    floating_widget: result[0].floating_widget,
                    floating_widget_position: result[0].floating_widget_position,
                    frequent_mentions: result[0].frequent_mentions,
                    hide_findshop_branding: result[0].hide_findshop_branding,
                    hide_product_link: result[0].hide_product_link,
                    hide_star_no_reviews: result[0].hide_star_no_reviews,
                    max_review_display: result[0].max_review_display,
                    loadMoreCount: result[0].max_review_display,
                    loadMoreShopCount: result[0].max_review_display,
                    page_widget: result[0].page_widget,
                    primary_color: result[0].primary_color,
                    product_widget: result[0].product_widget,
                    progressbar_theme: result[0].progressbar_theme,
                    rating_star_color: result[0].rating_star_color,
                    review_publish_date: result[0].review_publish_date,
                    reviewer_country_flag: result[0].reviewer_country_flag,
                    reviewer_name: result[0].reviewer_name,
                    reviewer_name_style: result[0].reviewer_name_style,
                    share_button: result[0].share_button,
                    shop_id: result[0].shop_id,
                    social_proof_widget: result[0].social_proof_widget,
                    social_proof_widget_delay: result[0].social_proof_widget_delay,
                    social_proof_widget_position: result[0].social_proof_widget_position,
                    social_proof_widget_style: result[0].social_proof_widget_style,
                    title_widget: result[0].title_widget,
                    title_widget_style: result[0].title_widget_style,
                    widget_font: result[0].widget_font,
                    widget_font_name: result[0].widget_font_name,
                    widget_language: result[0].widget_language,
                    widget_summary_bar: result[0].widget_summary_bar,
                    write_review_button: result[0].write_review_button,
                    hide_shop_review: result[0].hide_shop_review,
                    sortByShop: "Newest",
                    sortByProduct: 'Newest',
                    sortByProductValue: "newest",
                    sortByShopValue: "newest",
                    pageloading: false,
                    timeZone: result[0].time_zone,
                })
                this.getProductReviews(null, "newest", result[0].max_review_display ? Number(result[0].max_review_display) : 25, result[0].shop_id);
                this.getListShopReviews(result[0].shop_id, "newest", result[0].max_review_display ? Number(result[0].max_review_display) : 25,);

                WebFont.load({
                    google: {
                        families: [result[0].widget_font]
                    }
                });

                setTimeout(() => {
                    this.setState({ pageloading: false }); let root = document.getElementById('root');
                    let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
                    window.top.postMessage(message, "*");
                }, 2000);
            }
        })
    }

    getProductReviews = (product_id, sort, first, shop_id) => {
        var data = {
            product_id: product_id,
            sort: sort,
            first: first,
            shop_id: shop_id.toString(),
        }
        client
            .query({
                query: Constants.PRODUCT_REVIEW,
                variables: data,
            })
            .then(result => {
                if (result.data.WidgetListProductReview.data.length > 0) {
                    this.setState({
                        loading: false,
                        product: result.data.WidgetListProductReview.data.map((item) => ({
                            ...item,
                            IsReadMoreSelected: false
                        })),

                        // LoadMoreTotal: Math.ceil(result.data.WidgetListProductReview.paginatorInfo.total / first)
                    })
                    if (this.state.LoadMoreTotal === 1) {
                        this.setState({
                            LoadMoreTotal: Math.ceil(result.data.WidgetListProductReview.paginatorInfo.total / first)
                        })
                    }
                    let root = document.getElementById('root');
                    let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
                    window.top.postMessage(message, "*");

                    setTimeout(() => { this.setState({ pageloading: false }); }, 2000);
                }
            }).catch((error) => {
            });
    }

    getListShopReviews = (shop_id, sort, first) => {

        var data = {
            shop_id: shop_id.toString(),
            sort: sort,
            first: first
        }
        client
            .query({
                query: Constants.LIST_SHOP_REVIEW,
                variables: data,
            })
            .then(result => {
                if (result.data.WidgetListShopReview) {
                    this.setState({
                        loading: false,
                        shopReview: result.data.WidgetListShopReview.data.map((item) => ({
                            ...item,
                            IsReadMoreSelected: false
                        })),
                        // LoadMoreShopTotal: Math.ceil(result.data.WidgetListShopReview.paginatorInfo.total / first)
                    })
                    if (this.state.LoadMoreShopTotal === 1) {
                        this.setState({
                            LoadMoreShopTotal: Math.ceil(result.data.WidgetListShopReview.paginatorInfo.total / first)
                        })
                    }
                    let root = document.getElementById('root');
                    let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
                    window.top.postMessage(message, "*");
                }
            }).catch((error) => {
            });
    }
    render() {
        const { timeZone, pageloading, product, shopReview, sortByProduct, sortByShop, primary_color, review_publish_date, share_button, openTab, tabColor, hide_star_no_reviews, widget_design, customer_photo, reviewer_name, widget_font_name, reviewer_country_flag, hide_findshop_branding, background_color, floating_tab_background_color, hide_shop_review,hide_product_link } = this.state;
        return (
            pageloading ?
                <Loading layoutType="Customer_Review" />
                :
                this.state.isNotFound ?
                    <ErrorNotFound /> :

                    <FloatingTab isOpenFromCustomer={true}/>

                    // <div style={{ fontFamily: widget_font_name }} >
                    //     <div className="flex flex-wrap">
                    //         <div className="w-full ">
                    //             <div className="w-full border-b-2 header-tabs-position" style={{ backgroundColor: background_color, color: primary_color }}>
                    //                 <ul style={{ maxWidth: hide_shop_review == '0' ? '400px' : '200px' }}
                    //                     className="flex mb-0 list-none flex-wrap pt-3 flex-row"
                    //                     role="tablist"
                    //                 >
                    //                     <li className="-mb-px last:mr-0 flex-auto text-center">
                    //                         <Link
                    //                             className={
                    //                                 "bg-none text-md font-normal border-b-2 uppercase px-5 py-3 block leading-normal font-weight-500 " +
                    //                                 (openTab === 1
                    //                                     ? "text-black-400 border-" + ""
                    //                                     : "text-" + tabColor + "-300 bg-white border-0 ffont-weight-500")
                    //                             }
                    //                             onClick={e => {
                    //                                 e.preventDefault();
                    //                                 this.setState({ openTab: 1 });
                    //                                 setTimeout(() => {
                    //                                     let root = document.getElementById('root');
                    //                                     let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
                    //                                     window.top.postMessage(message, "*");
                    //                                 }, 200);
                    //                             }}
                    //                             data-toggle="tab"
                    //                             to="#"
                    //                             role="tablist"
                    //                         >
                    //                             Product Reviews
                    //                         </Link>
                    //                     </li>
                    //                     {hide_shop_review == '0' ?
                    //                         <li className="-mb-px last:mr-0 flex-auto text-center">
                    //                             <Link
                    //                                 className={
                    //                                     "bg-none text-md font-normal border-b-2 uppercase px-5 py-3 block leading-normal font-weight-500 " +
                    //                                     (openTab === 2
                    //                                         ? "text-black-400 border-" + ""
                    //                                         : "text-" + tabColor + "-300 bg-white border-0 font-weight-500")
                    //                                 }
                    //                                 onClick={e => {
                    //                                     e.preventDefault();
                    //                                     this.setState({ openTab: 2 });
                    //                                     setTimeout(() => {
                    //                                         let root = document.getElementById('root');
                    //                                         let message = { pageheight: root.scrollHeight, width: root.scrollWidth };
                    //                                         window.top.postMessage(message, "*");
                    //                                     }, 200);
                    //                                 }}
                    //                                 data-toggle="tab"
                    //                                 to="#"
                    //                                 role="tablist"
                    //                             >
                    //                                 Store Reviews
                    //                             </Link>
                    //                         </li>
                    //                         : null}
                    //                 </ul>
                    //             </div>
                    //             <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                    //                 {shopReview.length <= 0 ? null :
                    //                     <div className="productReviewSelect flex justify-end mr-2 mt-2 mb-2">

                    //                         <Menu as="div" className="relative inline-block text-left">
                    //                             {({ open }) => (
                    //                                 <>
                    //                                     <div>
                    //                                         <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-md font-medium text-gray-700 hover:bg-gray-50  focus:outline-none text-nowrap">
                    //                                             {!sortByShop ? "Sort By" :
                    //                                                 <div>Sort by:&nbsp;
                    //                                                     <span style={{ color: "#3E7BFA" }}>{sortByShop}</span>
                    //                                                 </div>
                    //                                             }
                    //                                             <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    //                                         </Menu.Button>
                    //                                     </div>

                    //                                     <Transition
                    //                                         show={open}
                    //                                         as={Fragment}
                    //                                         enter="transition ease-out duration-100"
                    //                                         enterFrom="transform opacity-0 scale-95"
                    //                                         enterTo="transform opacity-100 scale-100"
                    //                                         leave="transition ease-in duration-75"
                    //                                         leaveFrom="transform opacity-100 scale-100"
                    //                                         leaveTo="transform opacity-0 scale-95"
                    //                                     >
                    //                                         <Menu.Items
                    //                                             static
                    //                                             className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    //                                         >
                    //                                             <div className="py-1">
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByShop === "Newest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left py-2 text-md focus:outline-none'
                    //                                                             )}

                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByShopValue: "newest", sortByShop: "Newest" })
                    //                                                                 this.getListShopReviews(this.state.shop_id, "newest", this.state.max_review_display ? Number(this.state.max_review_display) : 25)
                    //                                                             }}
                    //                                                         >
                    //                                                             Newest
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByShop === "Oldest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left py-2 text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByShopValue: "oldest", sortByShop: "Oldest" })
                    //                                                                 this.getListShopReviews(this.state.shop_id, "oldest", this.state.max_review_display ? Number(this.state.max_review_display) : 25)
                    //                                                             }}
                    //                                                         >
                    //                                                             Oldest
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByShop === "High to Low" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left py-2 text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByShopValue: "high_to_low", sortByShop: "High to Low" })
                    //                                                                 this.getListShopReviews(this.state.shop_id, "high_to_low", this.state.max_review_display ? Number(this.state.max_review_display) : 25)
                    //                                                             }}
                    //                                                         >
                    //                                                             High to Low
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByShop === "Low to High" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left py-2 text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByShopValue: "low_to_high", sortByShop: "Low to High" })
                    //                                                                 this.getListShopReviews(this.state.shop_id, "low_to_high", this.state.max_review_display ? Number(this.state.max_review_display) : 25)
                    //                                                             }}
                    //                                                         >
                    //                                                             Low to High
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                             </div>
                    //                                         </Menu.Items>
                    //                                     </Transition>
                    //                                 </>
                    //                             )}
                    //                         </Menu>
                    //                     </div>
                    //                 }
                    //             </div>
                    //             <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    //                 {product.length <= 0 ? null :
                    //                     <div className="productReviewSelect flex justify-end mr-2 mt-2 mb-2">

                    //                         <Menu as="div" className="relative inline-block text-left">
                    //                             {({ open }) => (
                    //                                 <>
                    //                                     <div>
                    //                                         <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                    //                                             {!sortByProduct ? "Sort By" :
                    //                                                 <div>Sort by:&nbsp;
                    //                                                     <span style={{ color: "#3E7BFA" }}>{sortByProduct}</span>
                    //                                                 </div>
                    //                                             }
                    //                                             <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    //                                         </Menu.Button>
                    //                                     </div>

                    //                                     <Transition
                    //                                         show={open}
                    //                                         as={Fragment}
                    //                                         enter="transition ease-out duration-100"
                    //                                         enterFrom="transform opacity-0 scale-95"
                    //                                         enterTo="transform opacity-100 scale-100"
                    //                                         leave="transition ease-in duration-75"
                    //                                         leaveFrom="transform opacity-100 scale-100"
                    //                                         leaveTo="transform opacity-0 scale-95"
                    //                                     >
                    //                                         <Menu.Items
                    //                                             static
                    //                                             className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    //                                         >
                    //                                             <div className="py-1">
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByProduct === "Newest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left py-2 text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByProductValue: "newest", sortByProduct: "Newest" })
                    //                                                                 this.getProductReviews(null, "newest", this.state.max_review_display ? Number(this.state.max_review_display) : 25, this.state.shop_id)
                    //                                                             }}
                    //                                                         >
                    //                                                             Newest
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByProduct === "Oldest" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByProductValue: "oldest", sortByProduct: "Oldest" })
                    //                                                                 this.getProductReviews(null, "oldest", this.state.max_review_display ? Number(this.state.max_review_display) : 25, this.state.shop_id)
                    //                                                             }}
                    //                                                         >
                    //                                                             Oldest
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByProduct === "High to Low" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByProductValue: "high_to_low", sortByProduct: "High to Low" })
                    //                                                                 this.getProductReviews(null, "high_to_low", this.state.max_review_display ? Number(this.state.max_review_display) : 25, this.state.shop_id)
                    //                                                             }}
                    //                                                         >
                    //                                                             High to Low
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                                 <Menu.Item>
                    //                                                     {({ active }) => (
                    //                                                         <button
                    //                                                             className={classNames(
                    //                                                                 sortByProduct === "Low to High" ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                 'block px-4 py-2 w-100 text-left text-md focus:outline-none'
                    //                                                             )}
                    //                                                             onClick={() => {
                    //                                                                 this.setState({ sortByProductValue: "low_to_high", sortByProduct: "Low to High" })
                    //                                                                 this.getProductReviews(null, "low_to_high", this.state.max_review_display ? Number(this.state.max_review_display) : 25, this.state.shop_id)
                    //                                                             }}
                    //                                                         >
                    //                                                             Low to High
                    //                                                         </button>
                    //                                                     )}
                    //                                                 </Menu.Item>
                    //                                             </div>
                    //                                         </Menu.Items>
                    //                                     </Transition>
                    //                                 </>
                    //                             )}
                    //                         </Menu>
                    //                     </div>
                    //                 }
                    //             </div>

                    //             <div className="relative flex flex-col min-w-0 break-words w-full mb-8 rounded">
                    //                 <div className="px-0 py-0 flex-auto mb-4">
                    //                     <div className="tab-content tab-space">
                    //                         <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    //                             <div className={widget_design === "list" ? "" : "gap-6 grid lg:grid-cols-4 md:grid-cols-3 mx-auto sm:grid-cols-2 grid-cols-1"}>
                    //                                 {this.state.product.length ? this.state.product.map(
                    //                                     (x, idx) => {
                    //                                         var firstName = x.reviewer_name.split(' ').slice(0, 1).join(' ');
                    //                                         var lastName = x.reviewer_name.split(' ').slice(-1).join(' ');
                    //                                         var initials = firstName.charAt(0) + lastName.charAt(0);
                    //                                         var product_fields = [];
                    //                                         var OutLinedStar = 0;
                    //                                         if (x.rating) {
                    //                                             var blnHalfStar = (Math.floor(parseFloat(x.rating).toFixed(1)) < parseFloat(x.rating).toFixed(1));
                    //                                             for (let i = 1; i <= Math.floor(parseFloat(x.rating).toFixed(1)); i++) {
                    //                                                 product_fields.push(<FontAwesomeIcon icon={faStar} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                             }
                    //                                             if (blnHalfStar) {
                    //                                                 product_fields.push(<FontAwesomeIcon icon={faStarHalfAlt} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                                 OutLinedStar = 5 - Math.floor(parseFloat(x.rating).toFixed(1)) - 1
                    //                                             }
                    //                                             else {
                    //                                                 OutLinedStar = 5 - Math.floor(parseFloat(x.rating).toFixed(1));
                    //                                             }

                    //                                             if (5 - Math.floor(parseFloat(x.rating).toFixed(1)) !== 0) {
                    //                                                 for (let i = 1; i <= OutLinedStar; i++) {
                    //                                                     product_fields.push(<FontAwesomeIcon icon={faStarO} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                                 }
                    //                                             }
                    //                                         }

                    //                                         return (
                    //                                             widget_design === "list" ?
                    //                                                 <>
                    //                                                     <div className="shadow-md rounded-xl review-popup p-4 dark:bg-gray-800 relative px-3 mt-4" style={{ backgroundColor: background_color }}>
                    //                                                         <div className="flex items-center justify-between">
                    //                                                             <div className="flex items-center justify-between w-full">
                    //                                                                 {hide_star_no_reviews === "0" ?
                    //                                                                     <div className="flex items-center">
                    //                                                                         <FontAwesomeIcon icon={faStar} style={{ color: this.state.rating_star_color, fontSize: '18px' }} />
                    //                                                                         <div className="ml-2 ">
                    //                                                                             <span className="text-3xl font-bold" style={{ color: this.state.primary_color }}>{x.rating}</span>
                    //                                                                             <span className="text-3xl text-gray-400 font-normal" style={{ color: this.state.primary_color }}>/5</span>
                    //                                                                         </div>
                    //                                                                     </div>
                    //                                                                     : null
                    //                                                                 }
                    //                                                                 <div className="flex items-center justify-between">
                    //                                                                     <div className="flex items-center">
                    //                                                                         <div>
                    //                                                                             <p className="text-gray-500 text-xl font-normal">Is it helpful ?</p>
                    //                                                                         </div>
                    //                                                                         <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                    //                                                                             this.upvoteProduct(x, idx)
                    //                                                                         }} >
                    //                                                                             <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                             <label className="cursorp text-gray-600 text-xl font-medium">Yes ({CommonConfig.isConvert(x.upvote_count ? x.upvote_count : 0)})</label>
                    //                                                                         </div>
                    //                                                                         <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                    //                                                                             this.downvoteProduct(x, idx)
                    //                                                                         }} >
                    //                                                                             <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                             <label className="cursorp text-gray-600 text-xl font-medium">No({CommonConfig.isConvert(x.downvote_count ? x.downvote_count : 0)})</label>
                    //                                                                         </div>
                    //                                                                     </div>
                    //                                                                     {share_button === "1" ?
                    //                                                                         <div className="ml-3 items-center flex">
                    //                                                                             <Menu as="div" className="relative inline-block text-left">
                    //                                                                                 <div>
                    //                                                                                     <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                    //                                                                                         <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                    //                                                                                     </Menu.Button>
                    //                                                                                 </div>

                    //                                                                                 <Transition
                    //                                                                                     as={Fragment}
                    //                                                                                     enter="transition ease-out duration-100"
                    //                                                                                     enterFrom="transform opacity-0 scale-95"
                    //                                                                                     enterTo="transform opacity-100 scale-100"
                    //                                                                                     leave="transition ease-in duration-75"
                    //                                                                                     leaveFrom="transform opacity-100 scale-100"
                    //                                                                                     leaveTo="transform opacity-0 scale-95"
                    //                                                                                 >
                    //                                                                                     <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                    //                                                                                         <div className="py-1">
                    //                                                                                             <Menu.Item>
                    //                                                                                                 {({ active }) => (
                    //                                                                                                     <a
                    //                                                                                                         href={"https://www.facebook.com/sharer/sharer.php?u=https://s.shynr.co/share/" + x.id}
                    //                                                                                                         target="_blank"
                    //                                                                                                         className={classNames(
                    //                                                                                                             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                             'block px-4 py-2 text-xl font-medium'
                    //                                                                                                         )}
                    //                                                                                                     >
                    //                                                                                                         <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                    //                                                                                                     </a>
                    //                                                                                                 )}
                    //                                                                                             </Menu.Item>
                    //                                                                                             <Menu.Item>
                    //                                                                                                 {({ active }) => (
                    //                                                                                                     <a
                    //                                                                                                         href={"https://twitter.com/intent/tweet?url=https://s.shynr.co/share/" + x.id}
                    //                                                                                                         target="_blank"
                    //                                                                                                         className={classNames(
                    //                                                                                                             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                             'block px-4 py-2 text-xl font-medium'
                    //                                                                                                         )}
                    //                                                                                                     >
                    //                                                                                                         <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                    //                                                                                                     </a>
                    //                                                                                                 )}
                    //                                                                                             </Menu.Item>
                    //                                                                                         </div>
                    //                                                                                     </Menu.Items>
                    //                                                                                 </Transition>
                    //                                                                             </Menu>
                    //                                                                         </div>
                    //                                                                         : null}
                    //                                                                 </div>
                    //                                                             </div>
                    //                                                         </div>
                    //                                                         <div className="mt-4">
                    //                                                             <div className="relative">
                    //                                                                 <label className="text-2xl text-black-300 font-semibold review-title" style={{ color: primary_color }}>{x.review_title}</label>
                    //                                                                 <p className="text-gray-600 font-normal multiple-line-trancate1" style={{ color: primary_color }}>{ReactHtmlParser(x.review_body)}</p>
                    //                                                                 {x.review_body.length > CommonConfig.ReadMoreLen ?
                    //                                                                     !x.IsReadMoreSelected ?
                    //                                                                         <span className="d-none bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                    //                                                                             var tempProductReviewList = product;
                    //                                                                             tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                    //                                                                             this.setState({ product: tempProductReviewList })
                    //                                                                         }}>
                    //                                                                             <Link to="#" className="email-text bg-white pl-2">Read more</Link>
                    //                                                                         </span>
                    //                                                                         :
                    //                                                                         <span className="d-none bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                    //                                                                             var tempProductReviewList = product;
                    //                                                                             tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                    //                                                                             this.setState({ product: tempProductReviewList })
                    //                                                                         }}>
                    //                                                                             <Link to="#" className="email-text bg-white pl-2">Read more</Link>
                    //                                                                         </span>
                    //                                                                     : null
                    //                                                                 }
                    //                                                             </div>
                    //                                                             {customer_photo === "1" ?
                    //                                                                 (x.media1 === null && x.media2 === null && x.media3 === null && x.media4 === null) ? <div></div> :
                    //                                                                     <div className="flex">
                    //                                                                         {x.media1 === null ? <div></div> : <div>
                    //                                                                             <img src={x.media1} alt="badge" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                    //                                                                                 ev.target.src = ImgNotFound
                    //                                                                             }} />
                    //                                                                         </div>}
                    //                                                                         {x.media2 === null ? <div></div> : <div>
                    //                                                                             <img src={x.media2} alt="badge" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                    //                                                                                 ev.target.src = ImgNotFound
                    //                                                                             }} />
                    //                                                                         </div>}
                    //                                                                         {x.media3 === null ? <div></div> : <div>
                    //                                                                             <img src={x.media3} alt="badge" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                    //                                                                                 ev.target.src = ImgNotFound
                    //                                                                             }} />
                    //                                                                         </div>}
                    //                                                                         {x.media4 === null ? <div></div> : <div>
                    //                                                                             <img src={x.media4} alt="badge" className="w-52 h-52 mr-3 my-4 object-cover overflow-hidden" onError={(ev) => {
                    //                                                                                 ev.target.src = ImgNotFound
                    //                                                                             }} />
                    //                                                                         </div>}
                    //                                                                     </div>

                    //                                                                 : null}
                    //                                                             <div className=" flex items-center mt-4">
                    //                                                                 {x.public_reply === null ? null : <label className="text-black-600 text-xl font-semibold">Reply from shop</label>}
                    //                                                                 {x.public_replied_at === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                    //                                                                 </span>}
                    //                                                                 {review_publish_date === "1" ?
                    //                                                                     <p className="text-gray-400 text-xl font-normal ml-3">
                    //                                                                         {x.public_replied_at ? moment(x.public_replied_at).fromNow() : ""}
                    //                                                                     </p>
                    //                                                                     : null
                    //                                                                 }
                    //                                                             </div>
                    //                                                             {x.public_reply === null ? null : <div className="mt-2">
                    //                                                                 <p className="text-gray-600 font-normal" style={{ color: primary_color }}>{x.public_reply}</p>
                    //                                                             </div>}
                    //                                                         </div>
                    //                                                     </div>
                    //                                                     <div className="flex items-center justify-between mt-4 px-3 mb-4">
                    //                                                         <div className=" flex items-center">
                    //                                                             <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-16 h-16 pt-1 mr-2 rounded-full">{initials.toUpperCase()}</span>
                    //                                                             {reviewer_name === "1" ?
                    //                                                                 <label className="customer-name text-gray-400 text-xl font-semibold ml-3">{x.reviewer_name}
                    //                                                                 </label>
                    //                                                                 : null
                    //                                                             }
                    //                                                             {x.created_at === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                    //                                                             </span>}
                    //                                                             {review_publish_date === "1" ?
                    //                                                                 <p className="text-gray-400 text-xl font-normal ml-3">
                    //                                                                     {x.created_at ? moment(x.created_at).fromNow() : ""}
                    //                                                                 </p>
                    //                                                                 : null
                    //                                                             }
                    //                                                             {this.state.reviewer_country_flag == '1' ?
                    //                                                                 <div>&nbsp;&nbsp;<span className={"mt-2 ml-2 flag-icon flag-icon-" + x.reviewer_country.toLowerCase()}></span></div> : null}
                    //                                                         </div>
                    //                                                         {x.is_verified === "1" ?
                    //                                                             <div>
                    //                                                                 <span className="rounded-full uppercase text-light-green text-base font-medium px-3 py-2 leading-none flex items-center">
                    //                                                                     <img src={verifyBadgeGreen} alt="badge" className="text-light-green w-8 h-8 justify-between" /> Verified purchase
                    //                                                                 </span>
                    //                                                             </div>
                    //                                                             : null}
                    //                                                     </div>
                    //                                                 </>

                    //                                                 :


                    //                                                 <div className="shadow-lg rounded-xl overflow-hidden1 mb-3" style={{ backgroundColor: background_color }}>
                    //                                                     <div className="relative">
                    //                                                         <img alt="PRODUCT IMAGE" src={x.media1} className="object-cover overflow-hidden w-full h-80" onError={(ev) => {
                    //                                                             ev.target.src = ImgNotFound
                    //                                                         }} />
                    //                                                         <div className="top-3 right-3 w-8 absolute">
                    //                                                         </div>
                    //                                                     </div>
                    //                                                     <div className="p-4 border-bottom" style={{ height: "245px" }}>
                    //                                                         <div className="flex items-center mb-2">
                    //                                                             {hide_star_no_reviews === "0" ? <div className="">
                    //                                                                 <span className="text-5xl font-bold" style={{ color: primary_color }}>{x.rating}</span>
                    //                                                                 <div className="mt-1">

                    //                                                                     {product_fields}
                    //                                                                 </div>
                    //                                                             </div> : null}
                    //                                                         </div>
                    //                                                         <div >
                    //                                                             <label className="text-black-600 font-semibold mb-2 review-title" style={{ color: primary_color }}>{x.review_title}</label>
                    //                                                             <p className="text-gray-600 font-normal mb-2 multiple-line-trancate" style={{ color: primary_color, height: '60px' }}>{ReactHtmlParser(x.review_body)}</p>
                    //                                                         </div>
                    //                                                         <div className="mt-3 flex items-end justify-between">
                    //                                                             <div className="w-100">
                    //                                                                 <label className="text-gray-400 text-xl font-medium customer-name">{x.reviewer_name}
                    //                                                                     {this.state.reviewer_country_flag == '1' ?
                    //                                                                         <span className={`mt-2 ml-2 flag-icon flag-icon-${x.reviewer_country ? x.reviewer_country.toLowerCase() : null}`}></span> : null}</label>

                    //                                                                 <div className="block justify-between">
                    //                                                                     {review_publish_date === "1" ? <p className={x.created_at ? "text-gray-400 text-12 font-normal" : "d-none"}>
                    //                                                                         {x.created_at ? moment(x.created_at).fromNow() : ""}
                    //                                                                     </p> : null}
                    //                                                                     <div className="flex items-center">
                    //                                                                         <img src={verifyBadgeGreen} alt="" className="text-light-green w-8 h-8 justify-between" />
                    //                                                                         &nbsp; <span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                    //                                                                     </div>
                    //                                                                 </div>
                    //                                                             </div>

                    //                                                         </div>
                    //                                                     </div>
                    //                                                     <div className="p-4 flex items-center justify-between">
                    //                                                         <div className="flex items-center">
                    //                                                             <div>
                    //                                                                 <p className="text-gray-500 font-normal">helpful ?</p>
                    //                                                             </div>
                    //                                                             <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                    //                                                                 this.upvoteProduct(x, idx)
                    //                                                             }} >
                    //                                                                 <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                 <label className="cursorp text-gray-600 text-xl font-medium">({x.upvote_count ? x.upvote_count : 0})</label>
                    //                                                             </div>
                    //                                                             <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                    //                                                                 this.downvoteProduct(x, idx)
                    //                                                             }} >
                    //                                                                 <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                 <label className="cursorp text-gray-600 text-xl font-medium">({x.downvote_count ? x.downvote_count : 0})</label>
                    //                                                             </div>
                    //                                                         </div>
                    //                                                         {share_button === "1" ? <div className="flex items-center">
                    //                                                             <Menu as="div" className="relative inline-block text-left">
                    //                                                                 <div>
                    //                                                                     <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                    //                                                                         <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                    //                                                                     </Menu.Button>
                    //                                                                 </div>

                    //                                                                 <Transition
                    //                                                                     as={Fragment}
                    //                                                                     enter="transition ease-out duration-100"
                    //                                                                     enterFrom="transform opacity-0 scale-95"
                    //                                                                     enterTo="transform opacity-100 scale-100"
                    //                                                                     leave="transition ease-in duration-75"
                    //                                                                     leaveFrom="transform opacity-100 scale-100"
                    //                                                                     leaveTo="transform opacity-0 scale-95"
                    //                                                                 >
                    //                                                                     <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                    //                                                                         <div className="py-1">
                    //                                                                             <Menu.Item>
                    //                                                                                 {({ active }) => (
                    //                                                                                     <a
                    //                                                                                         href={"https://www.facebook.com/sharer/sharer.php?u=https://s.shynr.co/share/" + x.id}
                    //                                                                                         target="_blank"
                    //                                                                                         className={classNames(
                    //                                                                                             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                             'block px-4 py-2 text-xl font-medium'
                    //                                                                                         )}
                    //                                                                                     >
                    //                                                                                         <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                    //                                                                                     </a>
                    //                                                                                 )}
                    //                                                                             </Menu.Item>
                    //                                                                             <Menu.Item>
                    //                                                                                 {({ active }) => (
                    //                                                                                     <a
                    //                                                                                         href={"https://twitter.com/intent/tweet?url=https://s.shynr.co/share/" + x.id}
                    //                                                                                         target="_blank"
                    //                                                                                         className={classNames(
                    //                                                                                             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                             'block px-4 py-2 text-xl font-medium'
                    //                                                                                         )}
                    //                                                                                     >
                    //                                                                                         <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                    //                                                                                     </a>
                    //                                                                                 )}
                    //                                                                             </Menu.Item>
                    //                                                                         </div>
                    //                                                                     </Menu.Items>
                    //                                                                 </Transition>
                    //                                                             </Menu>
                    //                                                         </div> : null}
                    //                                                     </div>
                    //                                                 </div>
                    //                                         )
                    //                                     }
                    //                                 ) : null}

                    //                             </div>
                    //                             {product.length <= 0 ?
                    //                                 <div className="flex justify-center my-3"><span>No reviews yet</span></div>
                    //                                 : this.state.loadMorepage === this.state.LoadMoreTotal ? null :
                    //                                     <div>
                    //                                         <div className="flex justify-center my-3">
                    //                                             <button style={{ borderColor: '#0EA5E9' }} className="flex items-center text-blue-500 bg-transparent border border-solid  hover:bg-blue-500 text-md hover:text-white active:bg-blue-600 font-medium px-10 py-3 rounded-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => {
                    //                                                 this.setState({ loadMorepage: this.state.loadMorepage + 1, loading: true, loadMoreCount: Number(this.state.loadMoreCount) + Number(this.state.max_review_display) })
                    //                                                 this.getProductReviews(null, this.state.sortByProductValue, Number(this.state.loadMoreCount) + Number(this.state.max_review_display), this.state.shop_id)
                    //                                             }}>
                    //                                                 {this.state.loading ? <div class="mr-3 loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div> : null}
                    //                                                 Load More
                    //                                             </button>
                    //                                         </div>
                    //                                     </div>

                    //                             }
                    //                         </div>
                    //                         <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    //                             <div className={widget_design === "list" ? "" : "gap-6 grid lg:grid-cols-4 md:grid-cols-3 mx-auto sm:grid-cols-2 grid-cols-1"}>
                    //                                 {this.state.shopReview.length ?
                    //                                     this.state.shopReview.map(
                    //                                         (x, idx) => {
                    //                                             var firstName = x.reviewer_name.split(' ').slice(0, 1).join(' ');
                    //                                             var lastName = x.reviewer_name.split(' ').slice(-1).join(' ');
                    //                                             var initials = firstName.charAt(0) + lastName.charAt(0);
                    //                                             var shopReview_fields = [];
                    //                                             var OutLinedStar = 0;
                    //                                             if (x.average_rating) {
                    //                                                 var blnHalfStar = (Math.floor(parseFloat(x.average_rating).toFixed(1)) < parseFloat(x.average_rating).toFixed(1));
                    //                                                 for (let i = 1; i <= Math.floor(parseFloat(x.average_rating).toFixed(1)); i++) {
                    //                                                     shopReview_fields.push(<FontAwesomeIcon icon={faStar} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                                 }
                    //                                                 if (blnHalfStar) {
                    //                                                     shopReview_fields.push(<FontAwesomeIcon icon={faStarHalfAlt} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                                     OutLinedStar = 5 - Math.floor(parseFloat(x.average_rating).toFixed(1)) - 1
                    //                                                 }
                    //                                                 else {
                    //                                                     OutLinedStar = 5 - Math.floor(parseFloat(x.average_rating).toFixed(1));
                    //                                                 }

                    //                                                 if (5 - Math.floor(parseFloat(x.average_rating).toFixed(1)) !== 0) {
                    //                                                     for (let i = 1; i <= OutLinedStar; i++) {
                    //                                                         shopReview_fields.push(<FontAwesomeIcon icon={faStarO} className="mr-2 font-normal" style={{ color: this.state.rating_star_color, fontSize: "2rem" }} />);
                    //                                                     }
                    //                                                 }
                    //                                             }

                    //                                             return (
                    //                                                 widget_design === "list" ?
                    //                                                     <>
                    //                                                         <div className="shadow-md rounded-xl review-popup p-4 dark:bg-gray-800 relative px-3 mt-4" style={{ backgroundColor: background_color }}>
                    //                                                             <div className="flex items-center justify-between">
                    //                                                                 <div className="flex items-center justify-between w-full">
                    //                                                                     {hide_star_no_reviews === "0" ?
                    //                                                                         <div className="flex items-center">
                    //                                                                             <FontAwesomeIcon icon={faStar} style={{ color: this.state.rating_star_color, fontSize: '18px' }} />
                    //                                                                             <div className="ml-2 ">
                    //                                                                                 <span className="text-3xl font-bold" style={{ color: this.state.primary_color }}>{x.average_rating}</span>
                    //                                                                                 <span className="text-3xl text-gray-400 font-normal" style={{ color: this.state.primary_color }}>/5</span>
                    //                                                                             </div>
                    //                                                                         </div>
                    //                                                                         : null
                    //                                                                     }
                    //                                                                     <div className="flex items-center justify-between">
                    //                                                                         <div className="flex items-center">
                    //                                                                             <div>
                    //                                                                                 <p className="text-gray-500 text-xl font-normal">Is it helpful ?</p>
                    //                                                                             </div>
                    //                                                                             <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                    //                                                                                 this.upvoteShop(x, idx)
                    //                                                                             }}>
                    //                                                                                 <FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                    //                                                                                 <label className="cursorp text-gray-600 text-xl font-medium">Yes ({CommonConfig.isConvert(x.upvote_count ? x.upvote_count : 0)})</label>
                    //                                                                             </div>
                    //                                                                             <div className="cursorp ml-4 items-center flex" onClick={(e) => {
                    //                                                                                 this.downvoteShop(x, idx)
                    //                                                                             }}>
                    //                                                                                 <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                                 <label className="cursorp text-gray-500 text-12 font-medium text-nowrap">No ({CommonConfig.isConvert(x.downvote_count ? x.downvote_count : 0)})</label>
                    //                                                                             </div>
                    //                                                                         </div>
                    //                                                                         {share_button === "1" ?
                    //                                                                             <div className="ml-3 items-center flex">
                    //                                                                                 <Menu as="div" className="relative inline-block text-left">
                    //                                                                                     <div>
                    //                                                                                         <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                    //                                                                                             <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                    //                                                                                         </Menu.Button>
                    //                                                                                     </div>

                    //                                                                                     <Transition
                    //                                                                                         as={Fragment}
                    //                                                                                         enter="transition ease-out duration-100"
                    //                                                                                         enterFrom="transform opacity-0 scale-95"
                    //                                                                                         enterTo="transform opacity-100 scale-100"
                    //                                                                                         leave="transition ease-in duration-75"
                    //                                                                                         leaveFrom="transform opacity-100 scale-100"
                    //                                                                                         leaveTo="transform opacity-0 scale-95"
                    //                                                                                     >
                    //                                                                                         <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                    //                                                                                             <div className="py-1">
                    //                                                                                                 <Menu.Item>
                    //                                                                                                     {({ active }) => (
                    //                                                                                                         <a
                    //                                                                                                             href={"https://www.facebook.com/sharer/sharer.php?u=" + x.shop_setting.shop_url}
                    //                                                                                                             target="_blank"
                    //                                                                                                             className={classNames(
                    //                                                                                                                 active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                                 'block px-4 py-2 text-xl font-medium'
                    //                                                                                                             )}
                    //                                                                                                         >
                    //                                                                                                             <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                    //                                                                                                         </a>
                    //                                                                                                     )}
                    //                                                                                                 </Menu.Item>
                    //                                                                                                 <Menu.Item>
                    //                                                                                                     {({ active }) => (
                    //                                                                                                         <a
                    //                                                                                                             href={"https://twitter.com/intent/tweet?url=" + x.shop_setting.shop_url}
                    //                                                                                                             target="_blank"
                    //                                                                                                             className={classNames(
                    //                                                                                                                 active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                                 'block px-4 py-2 text-xl font-medium'
                    //                                                                                                             )}
                    //                                                                                                         >
                    //                                                                                                             <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                    //                                                                                                         </a>
                    //                                                                                                     )}
                    //                                                                                                 </Menu.Item>
                    //                                                                                             </div>
                    //                                                                                         </Menu.Items>
                    //                                                                                     </Transition>
                    //                                                                                 </Menu>
                    //                                                                             </div>
                    //                                                                             : null}
                    //                                                                     </div>
                    //                                                                 </div>
                    //                                                             </div>
                    //                                                             <div className="mt-4">
                    //                                                                 <div className="relative">
                    //                                                                     <label className="text-2xl text-black-300 font-semibold review-title" style={{ color: primary_color }}>{x.review_title}</label>
                    //                                                                     <p className="text-gray-600 font-normal multiple-line-trancate1" style={{ color: primary_color }}>{ReactHtmlParser(x.review_body)}</p>
                    //                                                                     {x.review_body.length > CommonConfig.ReadMoreLen ?
                    //                                                                         !x.IsReadMoreSelected ?
                    //                                                                             <span className="d-none bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                    //                                                                                 var tempProductReviewList = shopReview;
                    //                                                                                 tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                    //                                                                                 this.setState({ shopReview: tempProductReviewList })
                    //                                                                             }}>
                    //                                                                                 <Link to="#" className="email-text bg-white pl-2">Read more</Link>
                    //                                                                             </span>
                    //                                                                             :
                    //                                                                             <span className="d-none bottom-0 right-0 action-popover-2 flex justify-end absolute" onClick={() => {
                    //                                                                                 var tempProductReviewList = shopReview;
                    //                                                                                 tempProductReviewList[idx]['IsReadMoreSelected'] = true;
                    //                                                                                 this.setState({ shopReview: tempProductReviewList })
                    //                                                                             }}>
                    //                                                                                 <Link to="#" className="email-text bg-white pl-2">Read more</Link>
                    //                                                                             </span>
                    //                                                                         : null
                    //                                                                     }
                    //                                                                 </div>

                    //                                                                 <div className=" flex items-center mt-4">
                    //                                                                     {x.public_reply === null ? null : <label className="text-gray-400 text-xl font-semibold">Reply from shop</label>}
                    //                                                                     {x.public_replied_at === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                    //                                                                     </span>}
                    //                                                                     {review_publish_date === "1" ?
                    //                                                                         <p className="text-gray-400 text-xl font-normal ml-3">
                    //                                                                             {x.public_replied_at ? moment(x.public_replied_at).fromNow() : ""}
                    //                                                                         </p>
                    //                                                                         : null
                    //                                                                     }
                    //                                                                 </div>
                    //                                                                 {x.public_reply === null ? null : <div className="mt-2">
                    //                                                                     <p className="text-gray-600 font-normal" style={{ color: primary_color }}>{x.public_reply}</p>
                    //                                                                 </div>}
                    //                                                             </div>
                    //                                                         </div>
                    //                                                         <div className="flex items-center justify-between mt-4 px-3 mb-4" >
                    //                                                             <div className=" flex items-center">
                    //                                                                 <div>
                    //                                                                     <span class="text-gray-700 font-medium text-16 flex items-center justify-center bg-gray-300 w-16 h-16 mr-2 rounded-full pt-1">{initials.toUpperCase()}</span>
                    //                                                                 </div>
                    //                                                                 {reviewer_name === "1" ?
                    //                                                                     <label className="text-gray-400 text-xl font-semibold ml-3">{x.reviewer_name}
                    //                                                                     </label>
                    //                                                                     : null
                    //                                                                 }
                    //                                                                 {x.created_at === null ? null : <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                    //                                                                 </span>}
                    //                                                                 {review_publish_date === "1" ?
                    //                                                                     <p className="text-gray-400 text-xl font-normal ml-3">
                    //                                                                         {x.created_at ? moment(x.created_at).fromNow() : ""}
                    //                                                                     </p>
                    //                                                                     : null
                    //                                                                 }
                    //                                                                 {this.state.reviewer_country_flag == '1' && x.reviewer_country ?
                    //                                                                     <div>&nbsp;&nbsp;<span className={`mt-2 ml-2 flag-icon flag-icon-${x.reviewer_country ? x.reviewer_country.toLowerCase() : null}`}></span></div> : null}
                    //                                                             </div>
                    //                                                             {x.is_verified === "1" ?
                    //                                                                 <div>
                    //                                                                     <span className="rounded-full uppercase text-light-green text-base font-medium px-3 py-2 leading-none flex items-center">
                    //                                                                         <img src={verifyBadgeGreen} alt="badge" className="text-light-green w-8 h-8 justify-between" /> Verified purchase
                    //                                                                     </span>
                    //                                                                 </div>
                    //                                                                 : null}
                    //                                                         </div>
                    //                                                     </>
                    //                                                     :
                    //                                                     <div className="shadow-lg rounded-xl overflow-hidden1 mb-3" style={{ backgroundColor: background_color }}>

                    //                                                         <div className="p-4 border-bottom" style={{ height: "245px" }}>
                    //                                                             <div className="flex items-center mb-2">
                    //                                                                 {hide_star_no_reviews === "0" ? <div className="">
                    //                                                                     <span className="text-5xl font-bold" style={{ color: primary_color }}>{x.average_rating}</span>
                    //                                                                     <div className="mt-1">

                    //                                                                         {shopReview_fields}
                    //                                                                     </div>
                    //                                                                 </div> : null}
                    //                                                             </div>
                    //                                                             <div >
                    //                                                                 <label className="text-black-600 font-semibold mb-2 review-title" style={{ color: primary_color }}>{x.review_title}</label>
                    //                                                                 <p className="text-gray-600 font-normal mb-2 multiple-line-trancate" style={{ color: primary_color, height: '60px' }}>{ReactHtmlParser(x.review_body)}</p>
                    //                                                             </div>
                    //                                                             <div className="mt-3 flex items-end justify-between">
                    //                                                                 <div className="w-100">
                    //                                                                     <label className="text-gray-400 text-2xl font-medium customer-name">{x.reviewer_name}</label>
                    //                                                                     <div className="block justify-between">
                    //                                                                         {review_publish_date === "1" ? <p className={x.created_at ? "text-gray-400 text-12 font-normal" : "d-none"}>
                    //                                                                             {x.created_at ? moment(x.created_at).fromNow() : ""}
                    //                                                                         </p> : null}
                    //                                                                         <div className="flex items-center">
                    //                                                                             <img alt="badge" src={verifyBadgeGreen} className="text-light-green w-8 h-8 justify-between" /><span className="text-light-green text-nowrap uppercase text-12">Verified purchase</span>
                    //                                                                         </div>
                    //                                                                     </div>
                    //                                                                 </div>

                    //                                                             </div>
                    //                                                         </div>
                    //                                                         <div className="p-4 flex items-center justify-between">
                    //                                                             <div className="flex items-center">
                    //                                                                 <div>
                    //                                                                     <p className="text-gray-500 font-normal">helpful ?</p>
                    //                                                                 </div>
                    //                                                                 <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                    //                                                                     this.upvoteShop(x, idx)
                    //                                                                 }}>
                    //                                                                     <span><FontAwesomeIcon icon={faThumbsUp} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                     <label className="cursorp text-gray-500 text-12 font-medium text-nowrap">({x.upvote_count ? x.upvote_count : 0})</label>
                    //                                                                 </div>
                    //                                                                 <div className="cursorp ml-2 flex items-center" onClick={(e) => {
                    //                                                                     this.downvoteShop(x, idx)
                    //                                                                 }}>
                    //                                                                     <span><FontAwesomeIcon icon={faThumbsDown} style={{ color: '#707071' }} className="text-3xl mr-2 w-6 h-6 font-normal" /></span>
                    //                                                                     <label className="cursorp text-gray-500 text-12 font-medium text-nowrap">({x.downvote_count ? x.downvote_count : 0})</label>
                    //                                                                 </div>
                    //                                                             </div>
                    //                                                             {share_button === "1" ?
                    //                                                                 <div className="items-center flex">
                    //                                                                     <Menu as="div" className="relative inline-block text-left">
                    //                                                                         <div>
                    //                                                                             <Menu.Button className="text-gray-600 text-xl border-0 font-medium inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 text-gray-700 h-10">
                    //                                                                                 <FontAwesomeIcon icon={faShareAlt} style={{ color: '#707071' }} className="text-2xl mr-2 font-normal" />
                    //                                                                             </Menu.Button>
                    //                                                                         </div>

                    //                                                                         <Transition
                    //                                                                             as={Fragment}
                    //                                                                             enter="transition ease-out duration-100"
                    //                                                                             enterFrom="transform opacity-0 scale-95"
                    //                                                                             enterTo="transform opacity-100 scale-100"
                    //                                                                             leave="transition ease-in duration-75"
                    //                                                                             leaveFrom="transform opacity-100 scale-100"
                    //                                                                             leaveTo="transform opacity-0 scale-95"
                    //                                                                         >
                    //                                                                             <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: '999' }}>
                    //                                                                                 <div className="py-1">
                    //                                                                                     <Menu.Item>
                    //                                                                                         {({ active }) => (
                    //                                                                                             <a
                    //                                                                                                 href={"https://www.facebook.com/sharer/sharer.php?u=" + x.shop_setting.shop_url}
                    //                                                                                                 target="_blank"
                    //                                                                                                 className={classNames(
                    //                                                                                                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                     'block px-4 py-2 text-xl font-medium'
                    //                                                                                                 )}
                    //                                                                                             >
                    //                                                                                                 <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> Facebook
                    //                                                                                             </a>
                    //                                                                                         )}
                    //                                                                                     </Menu.Item>
                    //                                                                                     <Menu.Item>
                    //                                                                                         {({ active }) => (
                    //                                                                                             <a
                    //                                                                                                 href={"https://twitter.com/intent/tweet?url=" + x.shop_setting.shop_url}
                    //                                                                                                 target="_blank"
                    //                                                                                                 className={classNames(
                    //                                                                                                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //                                                                                                     'block px-4 py-2 text-xl font-medium'
                    //                                                                                                 )}
                    //                                                                                             >
                    //                                                                                                 <svg class="w-7 h-7 fill-current float-left mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg> Twitter
                    //                                                                                             </a>
                    //                                                                                         )}
                    //                                                                                     </Menu.Item>
                    //                                                                                 </div>
                    //                                                                             </Menu.Items>
                    //                                                                         </Transition>
                    //                                                                     </Menu>
                    //                                                                 </div> : null}
                    //                                                         </div>
                    //                                                     </div>
                    //                                             )
                    //                                         }
                    //                                     ) :
                    //                                     <div className="no-review"></div>
                    //                                 }

                    //                             </div>

                    //                             {shopReview.length <= 0 ?
                    //                                 <div className="flex justify-center my-3"><span>No reviews yet</span></div>
                    //                                 : this.state.loadMoreShoppage === this.state.LoadMoreShopTotal ? null :
                    //                                     <div className="flex justify-center my-3">
                    //                                         <button style={{ borderColor: '#0EA5E9' }} className="flex items-center text-blue-500 bg-transparent border border-solid  hover:bg-blue-500 text-md hover:text-white active:bg-blue-600 font-medium px-10 py-3 rounded-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => {
                    //                                             this.setState({ loadMoreShoppage: this.state.loadMoreShoppage + 1, loading: true, loadMoreShopCount: Number(this.state.loadMoreShopCount) + Number(this.state.max_review_display) })
                    //                                             this.getListShopReviews(this.state.shop_id, this.state.sortByShopValue, Number(this.state.loadMoreShopCount) + Number(this.state.max_review_display))
                    //                                         }}>
                    //                                             {this.state.loading ? <div class="mr-3 loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div> : null}
                    //                                             Load More
                    //                                         </button>
                    //                                     </div>
                    //                             }
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //             {hide_findshop_branding == '1' ? null :
                    //                 <div class="d-flex align-items-center pl-2 pr-2">
                    //                     <span class="mr-2 font-medium" style={{ color: primary_color }}>Powered by Shynr</span>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
        )
    }
}
