import { gql } from '@apollo/client';


export const PRODUCT_REVIEW = gql`
query WidgetListProductReview($shop_id:String,$product_id:ID, $sort: String, $first:Int!){
WidgetListProductReview(shop_id:$shop_id,product_id:$product_id, sort:$sort, first:$first) {
paginatorInfo{
count
currentPage
perPage
total
}   
data{
id
shop_id
product_title
rating
review_title
review_body
reviewer_name
reviewer_email
media1
media2      
media3
media4
reviewer_country
upvote_count
downvote_count
status
created_at
public_reply
public_replied_at
is_verified
product
{
title
handle
}
}
}
}`;

export const VIEW_PRODUCT_REVIEW = gql`
query ProductReview($id:ID) {
ProductReview(id:$id) {
id
shop_id
product_id
product_title
rating
review_title
review_body
reviewer_name
reviewer_email
reviewer_ip
reviewer_country
media1
media2
media3
media4
upvote_count
downvote_count
is_featured
is_verified
source
status
public_reply
public_replied_at
private_reply
private_replied_at
created_at
updated_at
deleted_at
product {
title
handle
}
}
}`;

export const VIEW_SHOP_REVIEW = gql`
query ShopReview($id:ID) {
ShopReview(id:$id) {
id
shop_id
average_rating
public_reply
private_reply
source
order_id
review_title
review_body
reviewer_name
reviewer_email
upvote_count
downvote_count
status
public_replied_at
private_replied_at
deleted_at
created_at
}
}`;

export const VIEW_WIDGET_SETTING = gql`
query WidgetViewSetting($shop_id:String) {
WidgetViewSetting(shop_id:$shop_id) {
shop_id
auto_install_widget
card_widget
title_widget
title_widget_style
product_widget
widget_font
widget_language
progressbar_theme
primary_color
rating_star_color
background_color
write_review_button
reviewer_name_style
review_publish_date
reviewer_country_flag
customer_photo
share_button
hide_findshop_branding
floating_widget
page_widget
social_proof_widget
social_proof_widget_style
social_proof_widget_position
floating_widget_position
widget_option
widget_option_file
max_review_display
product_widget_type
hide_product_link
hide_star_no_reviews
widget_summary_bar
product_schema
shop_schema
}
}`;
export const SOCIAL_PROOF_WIDGET = gql`
query WidgetSocialProofWidget($first:Int!, $shop_id:ID) {
WidgetSocialProofWidget(first:$first, shop_id:$shop_id) {
data {
id
shop_id
product_id
rating
review_title
review_body
reviewer_name
media1
created_at
product {
title
handle
}
}
}
}`;

export const LIST_SHOP_REVIEW = gql`
query WidgetListShopReview($shop_id:String, $sort: String, $first:Int!) {
WidgetListShopReview(shop_id:$shop_id, sort: $sort, first:$first) {
paginatorInfo{
count
currentPage
perPage
total
}
data{
id
shop_id
average_rating
public_reply
private_reply
source
upvote_count
downvote_count
order_id
review_title
review_body
reviewer_name
reviewer_email
public_replied_at
private_replied_at
created_at
shop_setting {
    shop_url
}
}
}
}`;
export const CUSTOMER_IMAGE_COUNT = gql`
query  CustomerImagesCount($product_id:ID,$shop_id:String) {
CustomerImagesCount(product_id:$product_id,shop_id:$shop_id) {
media_count
}
}`;
export const CUSTOMER_IMAGES = gql`
query CustomerImages($product_id:ID, $first:Int!,$shop_id:String) {
CustomerImages(product_id:$product_id,first:$first,shop_id:$shop_id) {
data {
media1
media2
media3
media4
}
}
}`;
export const FREQUENT_MENTION_TAGS = gql`
query  WidgetFrequentMetionsTags($product_id:ID,$shop_id:String) {
WidgetFrequentMetionsTags(product_id:$product_id,shop_id:$shop_id) {
tags
}
}`;
export const SUMMARY_BAR = gql`
query WidgetBars($product_id:ID,$shop_id:String) {
WidgetBars(product_id:$product_id,shop_id:$shop_id) {
overall_rating
active_product_reviews_count
star_5_count
star_4_count
star_3_count
star_2_count
star_1_count
}
}`;

export const WIDGET_LANGUAGE_PATH = gql`
query WidgetLanguageFile($shop_id:String) {
WidgetLanguageFile(shop_id:$shop_id) {
language_file_path
}
}`;

export const WRITER_REVIEW = gql`
mutation ProductReview($input: ProductReviewInput!) {
ProductReview(input: $input) {
data_status,
data_message
}
}`;
export const UPLOAD_IMAGE = gql`
mutation UploadReviewImage($file: [Upload!]!) {
UploadReviewImage(file: $file){
data_status
data_message
media1
media2
media3
media4
}
}`;
export const REQUEST_REVIEW = gql`
query  RequestReview($id: ID)  {
RequestReview(id: $id) {
    id
    shop_id
    order_id
    customer_id
    is_review_collected
    widget_font
    widget_font_name
    shop_setting {
    logo
    shop_name
    }
    customer {
    name
    email
    mobile
    }
    product {
    id
    product_id
    title
    handle
    product_image1
    variant_id
    variant_title
    }
    order{
    order_at
    display_order_id
    }
    setting_request_type {
    allow_skip_review
    accept_store_reviews
    allow_media_upload
    allow_video_upload
    }
    collect_review_count
    setting_widget {
    rating_star_color
    }
    }
    }`;


export const VERIFIED_REVIEW = gql`
mutation VerifiedProductReview($request_id: String, $reviewer_name: String,$reviewer_email: String,$reviewer_mobile: String,  $product_reviews: [VerifiedProductReviewInput], $store_review: VerifiedStoreReviewInput) {
    VerifiedProductReview(request_id: $request_id, reviewer_name: $reviewer_name,reviewer_email: $reviewer_email,reviewer_mobile: $reviewer_mobile,  product_reviews: $product_reviews, store_review: $store_review)  {
    data_status
    data_message
    shop_url
    }
    }`;
export const PRODUCT_DOWNVOTE = gql`
mutation ProductReviewDownvote($review_id: String!) {
ProductReviewDownvote(review_id: $review_id) {
data_status,
data_message
}
}`;
export const PRODUCT_UPVOTE = gql`
mutation ProductReviewUpvote($review_id: String!) {
ProductReviewUpvote(review_id: $review_id) {
data_status,
data_message
}
}`;
export const SHOP_DOWNVOTE = gql`
mutation ShopReviewDownvote($review_id: String!) {
ShopReviewDownvote(review_id: $review_id) {
data_status,
data_message
}
}`;

export const SHOP_UPVOTE = gql`
mutation ShopReviewUpvote($review_id: String!) {
ShopReviewUpvote(review_id: $review_id) {
data_status,
data_message
}
}`;

export const VALIDATE_URL = gql`
query ValidateUrl($url: String!){
ValidateUrl(url:$url) {
is_url_valid
}
}`;

export const EDIT_PRODUCT_REVIEW = gql`
query ProductReviewEdit($id: ID)  {
    ProductReviewEdit(id: $id) {
    id
    shop_id
    product_id
    product_title
    rating
    review_title
    review_body
    reviewer_name
    reviewer_email
    media1
    media2
    media3
    media4
    created_at
    product {
    title
    product_image1
    }
    shop_setting {
    shop_name
    logo
    }
    }
    }
`;

export const UPDATE_PRODUCT_REVIEW = gql`
mutation ProductReviewUpdate($id:ID!,$input: ProductReviewInput!) {
    ProductReviewUpdate(id:$id , input: $input) {
    data_status,
    data_message
    }
    }
`;

export const EDIT_SHOP_REVIEW = gql`
query ShopReviewEdit($id: ID)  {
    ShopReviewEdit(id: $id) {
        id
        shop_id
        average_rating
        review_title
        review_body
        reviewer_name
        reviewer_email
        created_at
        shop_setting {
        shop_name
        logo
        }
    }
    }
    
`;

export const UPDATE_SHOP_REVIEW = gql`
mutation ShopReviewUpdate($id:ID!,$input: ShopReviewInput!) {
    ShopReviewUpdate(id:$id , input: $input) {
    data_status,
    data_message
    }
    }
`;