import React, { Component } from 'react'
import Loading from '../Loading/Loading';
import ErrorMessage from '../ErrorMessage/errorMessage';
import ErrorNotFound from '../404Error/404Error';
import * as Constants from '../../utils/graphql_queries.js';
import { client } from '../../utils/config';
import { Stack, Thumbnail, Caption, DropZone } from '@shopify/polaris';
import CommonConfig from '../../utils/constant.js';
import { faStar, faStarHalfAlt, faStarAndCrescent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import WebFont from 'webfontloader';
import StarRatingComponent from 'react-star-rating-component';
import ImgNotFound from "../../assets/img/company-Image.png";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialState = {
    order_id: "",
    order_at: "",
    product_image1: "",
    title: "",
    allow_skip_review: "",
    accept_store_reviews: "",
    allow_media_upload: "",
    allow_video_upload: "",
    logo: "",
    productsOrdered: [],
    StepCount: 0,
    ErrSteps: 0,
    validImageTypes: ['image/jpeg', 'image/png'],
    shop_average_rating: 0,
    shop_review_body: '',
    shop_review_title: '',
    reviewer_name: '',
    reviewer_email: "",
    request_id: '',
    shop_id: '',
    progressWidth: 100,
    finalWidthPercentage: 0,
    IsValid: false,
    ProductID: '',
    ReviewID: null,
    IsSubmitEntered: false,
    pageloading: false,
    ratingColor: '#FDAC42',

    ProductReviewRatingErr: false,
    ProductReviewRatingErrText: '',
    ProductReviewTitleErr: false,
    ProductReviewTitleErrText: '',
    ProductReviewBodyErr: false,
    ProductReviewBodyErrText: '',


    shopAverageRatingErr: false,
    shopAverageRatingErrText: '',
    shopReviewBodyErr: false,
    shopReviewBodyErrText: '',
    shopReviewTitleErr: false,
    shopReviewTitleErrText: '',

    product_reviews_rating: 0,
    product_reviews_title: '',
    product_reviews_body: '',
    files: []
}

export default class UpdateProductReview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = async () => {
        
        await this.validateURL();
    }

    validateURL = async () => {
        this.setState({ pageloading: true })
         var data = {
            "url":  window.location.href
        }

        await client
            .query({
                query: Constants.VALIDATE_URL,
                variables: data,
            })
            .then(async result => {
                
                let response = await result.data;
                if (response.ValidateUrl[0].is_url_valid === "true") {
                    var url = new URL(window.location.href);
                    var reviewid = url.searchParams.get("reviewId");
                    if (!CommonConfig.isEmpty(reviewid)) {
                        await this.getReviewDetails(reviewid);
                        this.setState({ IsValid: true, ReviewID: reviewid })
                    }
                }
                else {
                    var url = new URL(window.location.href);
                    var reviewid = url.searchParams.get("reviewId");
                    if (!CommonConfig.isEmpty(reviewid)) {
                        await this.getReviewDetails(reviewid);
                        this.setState({ IsValid: true, ReviewID: reviewid })
                    }
                }
                
            }).catch(() => {
            });
        this.setState({ pageloading: false })
    }

    getReviewDetails = async (id) => {
        let data = {
            id: id
        }
        await client
            .query({
                query: Constants.EDIT_PRODUCT_REVIEW,
                variables: data,
            })
            .then(async result => {
                let response = await result.data;
                if (!CommonConfig.isObjectEmpty(response.ProductReviewEdit)) {
                    this.setState({ pageloading: true })
                    let mediaList = [];
                    for (let i = 1; i <= 4; i++) {
                        if (!CommonConfig.isEmpty(response.ProductReviewEdit["media" + i])) {
                            let convertedBase64Media = CommonConfig.convertImgToBase64(response.ProductReviewEdit["media" + i]);
                            await convertedBase64Media.then(async dataUrl => {
                                let fileData = CommonConfig.base64toFile(await dataUrl, CommonConfig.findFileNameFromURL(response.ProductReviewEdit["media" + i]));
                                if (fileData) {
                                    mediaList.push(fileData)
                                }
                            });
                        }
                    }
                    
                    this.setState({
                        shop_id: response.ProductReviewEdit.shop_id,
                        request_id: response.ProductReviewEdit.id,
                        reviewer_name: response.ProductReviewEdit.reviewer_name,
                        reviewer_email: response.ProductReviewEdit.reviewer_email,
                        product_image1: response.ProductReviewEdit.product.product_image1,
                        title: response.ProductReviewEdit.product.title,
                        logo: response.ProductReviewEdit.shop_setting.logo,
                        product_reviews_rating: response.ProductReviewEdit.rating ? Number(response.ProductReviewEdit.rating) : 0,
                        product_reviews_title: response.ProductReviewEdit.review_title,
                        product_reviews_body: response.ProductReviewEdit.review_body,
                        files: mediaList.length ? mediaList : []
                        // ratingColor: response.ProductReviewEdit.setting_widget.rating_star_color,
                        // widget_font: response.ProductReviewEdit.widget_font,
                        // widget_font_name: response.ProductReviewEdit.widget_font_name
                    })

                    // WebFont.load({
                    //     google: {
                    //         families: [response.RequestReview.widget_font]
                    //     }
                    // })
                    this.setState({ pageloading: false })
                } else {
                    this.setState({ pageloading: false })
                }
            }).catch(() => {
            });
    }

    validationForProduct = () => {
        let IsValid = true;

        if (this.state.product_reviews_rating === 0) {
            this.setState({ ProductReviewRatingErr: true, ProductReviewRatingErrText: 'Review rating is required' })
            IsValid = false;
        } else {
            this.setState({ ProductReviewRatingErr: false, ProductReviewRatingErrText: '' })
        }

        if (CommonConfig.isEmpty(this.state.product_reviews_title)) {
            this.setState({ ProductReviewTitleErr: true, ProductReviewTitleErrText: 'Review title is required' })
            IsValid = false;
        } else {
            this.setState({ ProductReviewTitleErr: false, ProductReviewTitleErrText: '' })
        }

        if (CommonConfig.isEmpty(this.state.product_reviews_body)) {
            this.setState({ ProductReviewBodyErr: true, ProductReviewBodyErrText: 'Review description is required' })
            IsValid = false;
        } else {
            this.setState({ ProductReviewBodyErr: false, ProductReviewBodyErrText: '' })
        }

        return IsValid;
    }

    submit = async () => {
        if (this.validationForProduct()) {
            this.setState({ IsSubmitEntered: false })
            var maindata = {
                "input": {
                    "rating": this.state.product_reviews_rating,
                    "review_title": this.state.product_reviews_title,
                    "review_body": this.state.product_reviews_body,
                    "reviewer_name": this.state.reviewer_name,
                    "reviewer_email": this.state.reviewer_email
                },
                "id": this.state.ReviewID
            }
            await client
                .mutate({
                    mutation: Constants.UPDATE_PRODUCT_REVIEW,
                    variables: CommonConfig.convertJSONValueToString(maindata),
                })
                .then(async result => {
                    if (result.data.ProductReviewUpdate.data_status === "success") {
                        this.props.history.push('/storefront/successmessage')
                        this.setState({ pageloading: false })
                    } else {
                        this.setState({ pageloading: false })
                    }
                }).catch((error) => {
                });
        }
    }

    shopVerifiedReview = async () => {
        if (this.validationForProduct()) {
            this.setState({ pageloading: true })
            if (this.state.files.length) {

                var data = {
                    file: this.state.files
                }

                var formData = new FormData()
                formData.append('operations', JSON.stringify({ "query": "mutation UploadReviewImage($file: [Upload!]!) {\nUploadReviewImage(file: $file){\ndata_status\ndata_message\nmedia1\nmedia2\nmedia3\nmedia4\n}\n}", "variables": { "file": null }, "operationName": "UploadReviewImage" }))

                if (data.file.length == 1) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"] }))
                    formData.append(0, data.file[0])
                }
                else if (data.file.length == 2) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], }))
                    formData.append(0, data.file[0])
                    formData.append(1, data.file[1])
                }
                else if (data.file.length == 3) {
                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.3"], }))
                    formData.append(0, data.file[0])
                    formData.append(1, data.file[1])
                    formData.append(2, data.file[2])
                }
                else {

                    formData.append('map', JSON.stringify({ "0": ["variables.file.0"], "1": ["variables.file.1"], "2": ["variables.file.2"], "3": ["variables.file.3"] }))
                    formData.append(0, data.file.length ? data.file[0] : null)
                    formData.append(1, data.file.length > 1 ? data.file[1] : null)
                    formData.append(2, data.file.length > 2 ? data.file[2] : null)
                    formData.append(3, data.file.length > 3 ? data.file[3] : null)
                }
                await fetch(client.link.options.uri, {
                    method: 'POST',
                    header: {
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/json; charset=UTF-8",
                    },
                    body: formData
                }).then(async res => { return await res.json() }).then(res => {

                    if (!CommonConfig.isObjectEmpty(res)) {
                        let files = []
                        files.push(
                            CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media1,
                            CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media2,
                            CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media3,
                            CommonConfig.isObjectEmpty(res) ? '' : res.data.UploadReviewImage.media4,
                        )
                        this.setState({ files: files });
                        if (this.state.files.length) {
                            this.submit();
                        }
                    }
                })
            } else {
                let files = []
                files.push('', '', '', '')
                this.setState({ files: files });
                if (this.state.files.length) {
                    this.submit();
                }
            }
        }
    }

    render() {

        const { pageloading, IsValid, progressWidth, order_id, order_at, logo, widget_font_name, ProductReviewRatingErr, ProductReviewRatingErrText, ProductReviewTitleErr, ProductReviewTitleErrText, ProductReviewBodyErr, ProductReviewBodyErrText, files } = this.state;
        // console.log("keke", files)
        return (
            <div>
                {pageloading ?
                    <Loading />
                    :
                    CommonConfig.isEmpty(this.state.ReviewID) ?
                        <ErrorNotFound />
                        :
                        <div style={{ fontFamily: widget_font_name }} className="ml-2 mr-2">
                            {IsValid ?
                                <div className="w-100 my-5">
                                    <div className="flex justify-center my-5 pt-7">
                                        <img alt="SHOP LOGO" src={logo} className="w-56" />
                                    </div>
                                    <div className="max-w-3xl mx-auto shadow-md rounded-xl bg-white">
                                        <div className='overflow-auto'>
                                            <div className="relative">
                                                <div className="relative">
                                                    <div className="overflow-hidden h-3 mb-4 text-xs flex rounded bg-indigo-200">
                                                        <div style={{ width: progressWidth + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <>
                                                <div className="px-5 text-center">
                                                    <label className="text-black-600 tracking-wider font-bold text-4xl">Rate your last purchase</label>
                                                    <div className="justify-center flex items-center mt-3">
                                                        <label className="text-gray-500 text-xl font-medium">Order no. </label>
                                                        <p className="text-black-500 text-md font-normal ml-3">
                                                            <strong>{order_id}</strong>
                                                        </p>
                                                        <span className="rounded-full h-2 w-2 bg-gray-400 ml-3">
                                                        </span>
                                                        <label className="text-gray-500 text-xl font-medium ml-3">Order date. </label>
                                                        <p className="text-black-500 text-md font-normal ml-2">
                                                            <strong>{order_at ? moment(order_at).format('MMM D,YYYY') : "No date available"}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="w-1/2 mx-auto">
                                                    <img alt="PRODUCT IMAGE" src={this.state.product_image1} className="object-cover overflow-hidden w-full h-80" onError={(ev) => {
                                                        ev.target.src = ImgNotFound
                                                    }} />
                                                </div>
                                                <br />
                                                <div className="text-center">
                                                    <label className="text-black-500 text-4xl font-medium">{this.state.title ? this.state.title : "Product name not found"}</label>
                                                </div>
                                                <br />
                                                <div className="px-5">
                                                    <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Rate this product</label>
                                                    <div className="mt-3">
                                                        <StarRatingComponent
                                                            name={`rate-`}
                                                            emptyStarColor={"gray"}
                                                            starColor={this.state.ratingColor}
                                                            renderStarIcon={() => <FontAwesomeIcon icon={faStar} className="text-3xl font-normal mr-2"
                                                            />}
                                                            starCount={5}
                                                            value={this.state.product_reviews_rating}
                                                            onStarHover={(e) => {
                                                                this.setState({ product_reviews_rating: e })
                                                            }}
                                                            onStarHoverOut={(e) => {
                                                                this.setState({ product_reviews_rating: e })
                                                            }}
                                                            onStarClick={(e) => {
                                                                this.setState({ product_reviews_rating: e })
                                                            }}
                                                        />
                                                        <span className='block'>{ProductReviewRatingErr ? CommonConfig.showErrorMsg(ProductReviewRatingErrText) : null}</span>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="px-5">
                                                    <label className="text-gray-600 tracking-wider font-medium text-2xl uppercase">Review this product</label>
                                                    <div className="mt-3">
                                                        <label className="text-gray-500">Review title  </label>
                                                        <input type="text" id="create-account-pseudo" className=" rounded-lg border-transparent flex-1 mb-2 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.product_reviews_title} onChange={(e) => {
                                                            this.setState({ product_reviews_title: e.target.value })
                                                        }} />
                                                        {ProductReviewTitleErr ? CommonConfig.showErrorMsg(ProductReviewTitleErrText) : null}
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <label className="text-gray-500">Review description</label>
                                                        <textarea rows="4" type="text" id="create-account-pseudo" className="resize-none rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-xl focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" name="pseudo" value={this.state.product_reviews_body} onChange={(e) => {
                                                            this.setState({ product_reviews_body: e.target.value })
                                                        }} />
                                                        {ProductReviewBodyErr ? CommonConfig.showErrorMsg(ProductReviewBodyErrText) : null}
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="dropzone-design-widget px-5">
                                                    {/* {allow_media_upload === "0" ? null : */}
                                                    {/* <DropZone style={{ width: 363 }} onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
                                                        if (files.length < 5 && acceptedFiles.length < 5) {
                                                            this.setState({ files: [...files, ...acceptedFiles] })
                                                        } else {
                                                            this.setState({ files: [] })
                                                        }
                                                    }} allowMultiple={true}>
                                                        {files.length ?
                                                            files.map((file, index) => (
                                                                <Stack alignment="center" key={index}>
                                                                    <Thumbnail
                                                                        size="small"
                                                                        alt={file.name}
                                                                        source={
                                                                            this.state.validImageTypes.includes(file.type)
                                                                                ? window.URL.createObjectURL(file)
                                                                                : file
                                                                        }
                                                                    />
                                                                    <div>
                                                                        {file.name}
                                                                    </div>
                                                                </Stack>
                                                            )) :
                                                            <DropZone.FileUpload actionTitle="Upload Media" actionHint={"Upload Media Upto 4 files - JPG, JPEG or PNG (max size 3 MB), MP4 (max size 30 MB)"} />
                                                        }
                                                    </DropZone> */}

                                                    <FilePond
                                                        // ref={ref => (this.pond = ref)}
                                                        files={files}
                                                        allowMultiple={true}
                                                        allowReorder={true}
                                                        credits={''}
                                                        instantUpload={true}
                                                        // server="./"
                                                        name="files"
                                                        labelIdle={this.state.videoUpload ? 'JPG, JPEG or PNG (max size 3 MB),  MP4 (max size 30 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>' : 'JPG, JPEG or PNG (max size 3 MB) <svg class="upload-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10a10 10 0 11-20 0 10 10 0 0120 0zM5.3 8.3l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L11 7.4V15a1 1 0 11-2 0V7.4L6.7 9.7a1 1 0 01-1.4-1.4z" fill="#5C5F62" class=""></path></svg> <span class="block filepond--label-action">Browse</span>'}
                                                        // oninit={() => this.handleDropZoneDrop()}
                                                        // onupdatefiles={fileItems => {
                                                        //     this.setState({
                                                        //         files: fileItems.map(fileItem => fileItem.file)
                                                        //     });
                                                        // }}
                                                        // onaddfile={(error,fileItems) => {
                                                        //     this.handleDropZoneDrop(fileItems)
                                                        // }}
                                                        onupdatefiles={(fileItems) => {
                                                            if (files.length < 5 && fileItems.length < 5) {
                                                                this.setState({ files: fileItems })
                                                            } else {
                                                                this.setState({ files: [] })
                                                            }
                                                        }}
                                                    />
                                                    {/* } */}
                                                </div>
                                            </>
                                        </div>
                                        <div className='bg-white'>
                                            <hr className="mt-4" />
                                            <div className="flex justify-end p-4">
                                                <button type="button" className="py-3 px-24 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " onClick={(e) => {
                                                    this.submit(e);
                                                }}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="flex items-center justify-center pb-5">
                                        <span className="mr-2 font-medium">
                                            Powered by Shynr
                                        </span>
                                    </div>
                                </div>
                                :
                                <ErrorMessage />
                            }
                        </div>
                }
            </div>
        )
    }
}
